import React from 'react';
import Layout from '../components/Layout';
import CommonPageBanner from '../components/CommonPageBanner';
import ScrmContentList from '../components/ScrmContentList';
import HomeLogos from '../components/HomeLogos';

export default () => (
  <Layout current="scens" subCurrent="scrm">
    <CommonPageBanner
      title="全渠道SCRM管理平台"
      description="与传统CRM系统相比，SCRM（Social CRM）是以营销互动为中心的全域客户管理系统，更偏重社交化粉丝的运营和管理，尤其是对微信生态粉丝运营需求的支持。"
      imgName="scrm"
    />
    <ScrmContentList />
    <HomeLogos />
  </Layout>
);
