import React from 'react';
import { Col } from 'antd';
import Container from './Container';
import ImageText from './ImageText';
import ImageTextDescription from './ImageTextDesctiption';
import TitledVerticalBoxes from './TitledVerticalBoxes';
import Ma1 from '../images/ma-1.png';
import Scen1 from '../images/scen-1.svg';
import Scen2 from '../images/scen-2.png';
import VerticalBox from './VerticalBox';

const SCRM_DATA = [
  {
    id: 'scrm-1',
    iconIndex: 34,
    title: '一物一码',
    description: '帮助品牌与粉丝实现跨越线上线下的数据追溯，\n可批量设置二维码',
  },
  {
    id: 'scrm-2',
    iconIndex: 39,
    title: '微信48小时',
    description: '对新粉丝48小时黄金转化周期的\n自动流程设置和运营',
  },
  {
    id: 'scrm-3',
    iconIndex: 41,
    title: '个性化菜单',
    description: '可对不同类型的微信粉丝展现相应的\n个性化自定义微信菜单',
  },
  {
    id: 'scrm-4',
    iconIndex: 42,
    title: '微信卡券',
    description: '对折扣、兑换、代金、优惠等类型的卡券\n进行领取、转赠、核销管理',
  },
  {
    id: 'scrm-5',
    iconIndex: 25,
    title: '商户中心',
    description: '对社交平台体系内的\n交易订单、商品、微信红包等进行统一管理',
  },
  {
    id: 'scrm-6',
    iconIndex: 17,
    title: '微信会员管理',
    description: '完善的会员营销体系，\n并可对接其他第三方会员管理系统',
  },
  {
    id: 'scrm-7',
    iconIndex: 43,
    title: '服务号对话能力',
    description: '服务号对话能力(原微信导购助手)的支持，\n数据的对接和打通',
  },
  {
    id: 'scrm-8',
    iconIndex: 3,
    title: '微信营销活动',
    description: '基于社交媒体的分享裂变活动和全员推广活动',
  },
];

export default () => {
  return (
    <div className="content-list-wrapper scrm">
      <section className="image-text-section white">
        <Container>
          <ImageText image={Ma1} imgWidth={417} imgHeight={335} title="全渠道客户管理" imageSize={11}>
            <ImageTextDescription label="打通客户的全渠道触点（电商、门店、官网、微信、App、小程序、线下活动、产品包装等），通过统一的身份管理汇集全渠道数据，形成多维度的用户数据画像，包含客户属性、生命周期阶段、价值评估、活跃度、标签、行为时间轴等用户全景画像。" />
          </ImageText>
        </Container>
      </section>
      <section className="image-text-section blue">
        <Container>
          <ImageText image={Scen1} imgWidth={397} imgHeight={324} title="社交互动统一管理" position="right" imageSize={11}>
            <ImageTextDescription label="对客户的社交营销触点，如微信图文、微页面H5、表单、邮件模板、短信模板、App推送等，进行一站式集中管理。配备样式丰富的内容模板库，无需设计，快速实现大规模的客户触达。更支持个性化动态内容，轻松实现内容触达的千人千面需求。" />
          </ImageText>
        </Container>
      </section>
      <TitledVerticalBoxes title="微信SCRM运营" sectionId='scrm'>
        {SCRM_DATA.map((item) => (
          <Col lg={6} md={12} sm={12} xs={24} className="icon-text-col same" key={item.id}>
            <VerticalBox {...item} />
          </Col>
        ))}
      </TitledVerticalBoxes>
      <section className="image-text-section blue">
        <Container>
          <ImageText image={Scen2} imgWidth={427} imgHeight={322} title="微信营销自动化" imageSize={11}>
            <ImageTextDescription label="Convertlab帮助用户搭建基于CDP客户数据平台+MA营销自动化的全域SCRM解决方案，可对接主流广告投放平台，进行智能化广告投放管理和效果优化。基于消费者生命周期的自动化旅程设计，实现Always-on不间断的营销运营和内容营销管理。并可对接其他第三方传统CRM系统，方案实施简便快捷。" />
          </ImageText>
        </Container>
      </section>
    </div>
  );
};
